<template>
  <div class="rounding-surveillance">
    <LoadingSpinner :isLoading="isLoading" />
    <div v-bind:class="mobileMenuActive ? 'block' : 'hidden'" @click="closeMenu()" class="fixed z-20 inset-0 bg-black opacity-50 transition-opacity lg:hidden"></div>
    <div class="flex flex-col">
      <div class="w-full bg-primary-50 px-2 py-2">
        <ValidationObserver ref="formAlarmSearch">
          <div class="flex flex-wrap">
            <div class="w-full lg:w-1/3 px-2">
              <BaseInput v-model.trim="searchQuery" type="text" field_name="IMEI/Telematiknummer/Serienummer" rules="required" />
            </div>
            <div class="w-full lg:w-1/3 px-2">
              <ValidationProvider name="Datum" rules="required" v-slot="{ classes, errors }">
                <div class="input-validate" :class="classes">
                  <label class="text-gray-600 text-sm font-bold pb-2 font-serif pt-0 mt-0">Datum<b class="text-red-500 ml-1">*</b></label>
                  <div class="datepicker-wrapper height-large">
                    <date-picker v-model="date_range" type="date" range :editable="false" :clearable="false" :disabled-date="disableDate">
                      <template v-slot:footer="{ emit }">
                        <div class="text-left hidden lg:block">
                          <button class="datepicker-action-btn mr-1" @click="selectToday(emit)">Idag</button>
                          <button class="datepicker-action-btn mr-1" @click="selectCurrentWeek(emit)">Denna vecka</button>
                          <button class="datepicker-action-btn mr-1" @click="selectLastWeek(emit)">Förra veckan</button>
                          <button class="datepicker-action-btn mr-1" @click="selectCurrentMonth(emit)">Denna månad</button>
                          <button class="datepicker-action-btn mr-1" @click="selectLastMonth(emit)">Förra månaden</button>
                        </div>
                      </template>
                    </date-picker>
                  </div>
                  <span>{{ errors[0] }}</span>
                </div>
              </ValidationProvider>
            </div>
            <div class="w-full lg:w-1/3 px-2">
              <button class="btn-blue w-full py-1 mt-7 text-lg" @click="getAlarmGPSLogs()">
                Sök
                <BaseIcon icon="search" class="ml-2" />
              </button>
            </div>
          </div>
        </ValidationObserver>
      </div>
      <div v-show="showResult" class="w-full bg-action-bar mt-5 px-2 py-2 clearfix">
        <div class="float-left flex flex-wrap">
          <div class="rounded bg-primary-300 py-1 px-3 text-white mx-2 font-bold text-sm cursor-pointer" v-bind:class="!toggle_map ? 'bg-primary-500' : 'hover:bg-primary-400'" @click="changeToggleMap(false)">Logg</div>
          <div class="rounded bg-primary-300 py-1 px-3 text-white mx-2 font-bold text-sm cursor-pointer" v-bind:class="toggle_map ? 'bg-primary-500' : 'hover:bg-primary-400'" @click="changeToggleMap(true)">Karta</div>
        </div>
      </div>
      <div v-show="showResult" class="w-full bg-gray-50 px-2 py-2 clearfix mt-2">
        <div v-if="!toggle_map" class="float-left flex flex-wrap">
          <button class="btn-secondary-outline text-xxs mx-1 mt-2 lg:mt-0" v-bind:class="table_tab == 1 ? 'bg-secondary-600 text-white border-secondary-600' : 'hover:bg-secondary-400 hover:border-secondary-400 hover:text-white'" @click="changeTableFilter(1)">Alla</button>
          <button class="btn-secondary-outline text-xxs mx-1 mt-2 lg:mt-0" v-bind:class="table_tab == 2 ? 'bg-secondary-600 text-white border-secondary-600' : 'hover:bg-secondary-400 hover:border-secondary-400 hover:text-white'" @click="changeTableFilter(2)">Skarptlarm</button>
          <button class="btn-secondary-outline text-xxs mx-1 mt-2 lg:mt-0" v-bind:class="table_tab == 3 ? 'bg-secondary-600 text-white border-secondary-600' : 'hover:bg-secondary-400 hover:border-secondary-400 hover:text-white'" @click="changeTableFilter(3)">Internlarm</button>
          <button class="btn-secondary-outline text-xxs mx-1 mt-2 lg:mt-0" v-bind:class="table_tab == 4 ? 'bg-secondary-600 text-white border-secondary-600' : 'hover:bg-secondary-400 hover:border-secondary-400 hover:text-white'" @click="changeTableFilter(4)">Man-down</button>
          <button class="btn-secondary-outline text-xxs mx-1 mt-2 lg:mt-0" v-bind:class="table_tab == 5 ? 'bg-secondary-600 text-white border-secondary-600' : 'hover:bg-secondary-400 hover:border-secondary-400 hover:text-white'" @click="changeTableFilter(5)">Low battery</button>
          <button class="btn-secondary-outline text-xxs mx-1 mt-2 lg:mt-0" v-bind:class="table_tab == 6 ? 'bg-secondary-600 text-white border-secondary-600' : 'hover:bg-secondary-400 hover:border-secondary-400 hover:text-white'" @click="changeTableFilter(6)">Radiofyr</button>
          <button class="btn-secondary-outline text-xxs mx-1 mt-2 lg:mt-0" v-bind:class="table_tab == 7 ? 'bg-secondary-600 text-white border-secondary-600' : 'hover:bg-secondary-400 hover:border-secondary-400 hover:text-white'" @click="changeTableFilter(7)">Provlarmutfört</button>
          <button class="btn-secondary-outline text-xxs mx-1 mt-2 lg:mt-0" v-bind:class="table_tab == 8 ? 'bg-secondary-600 text-white border-secondary-600' : 'hover:bg-secondary-400 hover:border-secondary-400 hover:text-white'" @click="changeTableFilter(8)">Timeralarm</button>
          <button class="btn-secondary-outline text-xxs mx-1 mt-2 lg:mt-0" v-bind:class="table_tab == 9 ? 'bg-secondary-600 text-white border-secondary-600' : 'hover:bg-secondary-400 hover:border-secondary-400 hover:text-white'" @click="changeTableFilter(9)">On/Off</button>
          <button class="btn-secondary-outline text-xxs mx-1 mt-2 lg:mt-0" v-bind:class="table_tab == 10 ? 'bg-secondary-600 text-white border-secondary-600' : 'hover:bg-secondary-400 hover:border-secondary-400 hover:text-white'" @click="changeTableFilter(10)">Laddar</button>
          <button class="btn-secondary-outline text-xxs mx-1 mt-2 lg:mt-0" v-bind:class="table_tab == 11 ? 'bg-secondary-600 text-white border-secondary-600' : 'hover:bg-secondary-400 hover:border-secondary-400 hover:text-white'" @click="changeTableFilter(11)">GSM</button>
          <button class="btn-secondary-outline text-xxs mx-1 mt-2 lg:mt-0" v-bind:class="table_tab == 12 ? 'bg-secondary-600 text-white border-secondary-600' : 'hover:bg-secondary-400 hover:border-secondary-400 hover:text-white'" @click="changeTableFilter(12)">Geofence</button>
        </div>
        <div v-if="toggle_map" class="float-left flex flex-wrap">
          <button class="btn-secondary-outline text-xxs mx-1 mt-2 lg:mt-0" v-bind:class="map_filter == 1 ? 'bg-secondary-600 text-white border-secondary-600' : 'hover:bg-secondary-400 hover:border-secondary-400 hover:text-white'" @click="changeMapFilter(1)">Alla</button>
          <button class="btn-secondary-outline text-xxs mx-1 mt-2 lg:mt-0" v-bind:class="map_filter == 2 ? 'bg-secondary-600 text-white border-secondary-600' : 'hover:bg-secondary-400 hover:border-secondary-400 hover:text-white'" @click="changeMapFilter(2)">Skarptlarm</button>
          <button class="btn-secondary-outline text-xxs mx-1 mt-2 lg:mt-0" v-bind:class="map_filter == 3 ? 'bg-secondary-600 text-white border-secondary-600' : 'hover:bg-secondary-400 hover:border-secondary-400 hover:text-white'" @click="changeMapFilter(3)">Daglig spårning</button>
          <button class="btn-secondary-outline text-xxs mx-1 mt-2 lg:mt-0" v-bind:class="map_filter == 4 ? 'bg-secondary-600 text-white border-secondary-600' : 'hover:bg-secondary-400 hover:border-secondary-400 hover:text-white'" @click="changeMapFilter(4)">Aktuell plats</button>
        </div>
      </div>
      <div v-show="showResult" class="w-full mt-6">
        <div v-show="!toggle_map">
          <vue-good-table
            styleClass="vgt-table"
            row-style-class="text-sm"
            :columns="columns"
            :rows="rows_filtered"
            :search-options="{
              enabled: true,
              placeholder: 'Sök',
            }"
            :pagination-options="{
              enabled: true,
              perPage: 20,
              perPageDropdown: [20, 50, 100],
              dropdownAllowAll: false,
            }"
          >
            <div slot="table-actions">
              <div class="flex flex-wrap">
                <button class="btn-blue mx-1 mt-2 lg:mt-0" @click="exportData()">
                  Export
                  <BaseIcon icon="file-excel" class="ml-1" />
                </button>
              </div>
            </div>
            <div slot="emptystate" class="flex flex-wrap flex-col items-center my-5 text-sm">
              Inga loggar hittades
            </div>
          </vue-good-table>
        </div>
        <div v-show="toggle_map" class="unit-gps-wrapper">
          <div class="w-full lg:w-5/6 map-container">
            <GmapMap :center="center" :zoom="zoom" map-type-id="roadmap" class="google_map_wrapper" ref="map" :options="{ fullscreenControl: mapFullScreenControl }">
              <GmapCluster :zoom-on-click="true">
                <GmapMarker v-for="(m, index) in markers_filtered" :position="m.position" :clickable="true" :draggable="false" @mouseover="showInfoWindow($event, m.infoText, true)" @mouseout="showInfoWindow($event, m.infoText, false)" :key="index" :icon="{ url: m.icon }"></GmapMarker>
              </GmapCluster>
              <GmapCluster :zoom-on-click="true" :styles="[{ url: require('@/assets/icon/warning.png'), width: 40, height: 34, textSize: 18, anchorText: [4, 0], textColor: '#fff' }]">
                <GmapMarker v-for="(m, i) in push_button_markers_filtered" :key="i" :position="m.position" :clickable="true" :draggable="false" :icon="{ url: require('@/assets/icon/map-warning.svg') }" @mouseover="showInfoWindow($event, m.infoText, true)" @mouseout="showInfoWindow($event, m.infoText, false)"></GmapMarker>
              </GmapCluster>
              <GmapInfoWindow :options="infoOptions" :position="infoWindowPos" :opened="infoWinOpen" @closeclick="infoWinOpen = false"> </GmapInfoWindow>
              <GmapPolyline v-for="(p, index) in paths_filtered" :key="index" :options="p.options" :path="p.path" @mouseover="showInfoWindowPath($event, p.infoText, true, index)" @mouseout="showInfoWindowPath($event, p.infoText, false, index)"></GmapPolyline>
            </GmapMap>
          </div>
          <transition enter-active-class="transition ease-out duration-100" enter-class="transform translate-x-2 opacity-0" enter-to-class="transform translate-x-0 opacity-100" leave-active-class="transition ease-in duration-75" leave-class="transform translate-x-0 opacity-100" leave-to-class="transform translate-x-2 opacity-0">
            <div class="absolute top-0 right-0 min-h-screen w-4/6 md:w-2/6 lg:min-h-full lg:w-1/6 bg-white position-inherit z-30" v-if="mobileMenuActive">
              <div class="scroll-container-alarm border border-secondary-300">
                <div class="py-3 text-sm text-center text-secondary-600" v-if="alarm == null">
                  <div>Inga larm hittade</div>
                </div>
                <div v-if="alarm" class="alarm-item bg-white border-b border-secondary-300">
                  <div class="hover:bg-primary-50 cursor-pointer py-4 px-4" @click="focusAlarm()">
                    <div class="text-sm text-primary-500 font-bold mb-1">
                      <span v-if="new RegExp('^SRT').test(alarm.type)" class="last-seen device-active-status" v-bind:class="{ inactive: alarm.lastSeenTime > 1000 * 60 * 60 }">⬤</span>
                      <span v-if="new RegExp('^TWIG').test(alarm.type)" class="last-seen device-active-status" v-bind:class="{ inactive: alarm.lastSeenTime > 1000 * 60 * 60 * 2.5 }">⬤</span>
                      <span v-if="new RegExp('^EMERIT').test(alarm.type)" class="last-seen device-active-status" v-bind:class="{ inactive: alarm.lastSeenTime > 1000 * 60 * 60 }">⬤</span>
                      <span v-if="new RegExp('^TELTONIKA').test(alarm.type)" class="last-seen device-active-status" v-bind:class="{ inactive: alarm.lastSeenTime > 1000 * 60 * 60 }">⬤</span>
                      {{ alarm.imei_number }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </transition>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/locale/sv";
import "vue2-datepicker/index.css";
import { gmapApi } from "vue2-google-maps";

export default {
  name: "TechnicianSecurtool",
  title() {
    return `SecurTool - Tekniker | SecurCloud`;
  },
  components: {
    DatePicker,
  },
  data() {
    return {
      showResult: false,
      mobileMenuActive: false,
      mapFullScreenControl: true,
      pathFilter: true,
      isLoading: false,
      searchQuery: "",
      toggle_map: false,
      zoom: this.$store.state.mapZoom,
      center: this.$store.state.mapCenter,
      table_tab: 1,
      map_filter: 1,
      alarm: null,
      date_range: [],
      gps: null,
      gps_log: [],
      columns: [
        { label: "IMEI", field: "imei_number" },
        { label: "Timestamp", field: "createdAt", type: "date", formatFn: this.formatDate },
        { label: "Rapportorsak", field: "log_report" },
      ],
      rows: [],
      rows_filtered: [],
      markers: [],
      markers_filtered: [],
      paths: [],
      paths_filtered: [],
      push_button_markers: [],
      push_button_markers_filtered: [],
      infoWindowPos: null,
      infoWinOpen: false,
      currentMidx: null,
      infoOptions: { content: "", pixelOffset: { width: 0, height: -35 } },
      alarmCircles: [],
      email_setting: null,
      polygonOptions: {
        strokeColor: "#1A8DE9",
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: "#1A8DE9",
        fillOpacity: 0.4,
        editable: false,
        draggable: false,
      },
    };
  },

  methods: {
    getAlarmGPSLogs() {
      this.$refs.formAlarmSearch.validate().then((success) => {
        if (!success) {
          return;
        }

        this.getGPSLogs();
        this.$refs.formAlarmSearch.reset();
      });
    },

    async getGPSLogs() {
      try {
        this.isLoading = true;

        let start_date = new Date(this.moment(this.date_range[0]).startOf("day")).toISOString();
        let end_date = new Date(this.moment(this.date_range[1]).endOf("day")).toISOString();

        let searchQuery = this.searchQuery.replace(/\s/g, "");

        let response = await this.axios.get(`${process.env.VUE_APP_SERVER_URL}/gps-log/alarm/${searchQuery}/${start_date}/${end_date}`);

        if (response.data.alarm == null) {
          this.handleError(null, `Inget resultat hittades för ${response.data.searchQuery}`);
          this.isLoading = false;
          this.showResult = false;
          this.alarm = null;
          this.rows = [];
          this.rows_filtered = [];
          this.markers = [];
          this.markers_filtered = [];
          this.paths = [];
          this.paths_filtered = [];
          this.push_button_markers = [];
          this.push_button_markers_filtered = [];
          return;
        }

        this.alarm = response.data.alarm;
        this.gps = response.data.gps;
        this.gps_log = response.data.logs;

        this.rows = [];
        this.rows_filtered = [];
        this.markers = [];
        this.markers_filtered = [];
        this.paths = [];
        this.paths_filtered = [];
        this.push_button_markers = [];
        this.push_button_markers_filtered = [];

        if (this.gps && this.alarm.gps_log_active) {
          let lastSeenObj = this.getAlarmLastSeenTime(this.gps.updatedAt);
          this.alarm.lastSeen = lastSeenObj.lastSeen;
          this.alarm.lastSeenTime = lastSeenObj.lastSeenTime;

          if (this.gps.gps_pos.lat != null) {
            // Standard icon
            let icon = require("@/assets/icon/new_small_map_marker.png");
              // Car icon 
            if (new RegExp(/^TELTONIKA/g).test(this.alarm.type)) icon = require("@/assets/icon/car.png");
            // Icon depending on device type
            if (this.alarm.type == "SRT430") icon = require("@/assets/icon/small_SRT430.png");
            if (this.alarm.type == "SRT341") icon = require("@/assets/icon/srt341.png");
            if (this.alarm.type == "SRT406") icon = require("@/assets/icon/small_SRT406.png");
            if (this.alarm.type == "SRT406i") icon = require("@/assets/icon/small_SRT406.png");

            // Twig devices icons
            if (/^TWIG/.test(this.alarm.type)) {
              icon = require("@/assets/icon/twig_map_marker.png");

              if (this.alarm.type == "TWIG One EX") icon = require("@/assets/icon/twig_one_ex.png");
              else if (/^TWIG One/.test(this.alarm.type)) icon = require("@/assets/icon/twig_one.png");
            }

            this.markers.push({
              icon: icon,
              position: { lat: this.gps.gps_pos.lat, lng: this.gps.gps_pos.lng },
              infoText: `IMEI: <b>${this.alarm.imei_number}</b>`,
              unit_number: this.alarm.parent_id,
              imei_number: this.alarm.imei_number,
            });
          }
        }
        this.markers_filtered = this.markers;

        // gps logs
        this.gps_log.forEach((el) => {
          // table
          if (el.log_type != "location_update") this.rows.push(el);

          if (el.log_type == "push_button" && el.gps_pos.lat != null) {
            this.push_button_markers.push({
              position: el.gps_pos,
              unit_number: el.unit_number,
              customer_id: el.customer_id,
              imei_number: el.imei_number,
              infoText: `<b>Push Button</b><br>
                        IMEI: <b>${el.imei_number}</b><br>
                        Timestamp: <b>${this.formatDate(el.createdAt)}</b><br>
                        Lat: <b>${el.gps_pos.lat.toFixed(3)}</b>, Lng: <b>${el.gps_pos.lng.toFixed(3)}</b>
                        `,
            });
          }

          // map
          // populate path array
          let date = this.moment(el.createdAt).format("YYYY-MM-DD");
          let i = _.findIndex(this.paths, { imei_number: el.imei_number, date: date });

          if (i >= 0) {
            this.paths[i].location_log.push({
              lat: el.gps_pos.lat,
              lng: el.gps_pos.lng,
              speed: el.alarm_speed,
              timestamp: el.createdAt,
            });
          } else {
            this.paths.push({
              name: el.alarm_name,
              alarm_id: el.alarm_id,
              unit_number: el.unit_number,
              imei_number: el.imei_number,
              date: date,
              type: "daily_tracking",
              location_log: [
                {
                  lat: el.gps_pos.lat,
                  lng: el.gps_pos.lng,
                  speed: el.alarm_speed,
                  timestamp: el.createdAt,
                },
              ],
              path: [],
              distance_travelled: 0,
              options: {
                strokeColor: this.getRandomPathColor(),
                strokeOpacity: 0.8,
                strokeWeight: 3.4,
                icons: [],
              },
              infoText: `IMEI: <b>${el.imei_number}</b><br>
                        Datum: <b>${date}</b>`,
            });
          }
        });

        this.rows = this.rows.sort(function(a, b) {
          return new Date(b.createdAt) - new Date(a.createdAt);
        });
        this.rows_filtered = this.rows;
        this.push_button_markers_filtered = this.push_button_markers;

        // filter path
        this.paths.forEach((el) => {
          if (this.pathFilter) {
            let { paths_filtered, distance_travelled } = this.removePathNoise(el);

            if (distance_travelled > 0.5) {
              el.path = paths_filtered;
              el.infoText += `<br>Tillryggalagd sträcka: <b>${distance_travelled} km</b>`;
            }
          } else {
            el.path = el.location_log;
          }
        });

        // snap to road path
        response.data.snap_roads_paths.forEach((element) => {
          let date = this.moment(element.path_date).format("YYYY-MM-DD");

          if (this.alarm) {
            this.paths.push({
              path: element.path,
              unit_number: element.unit_id,
              imei_number: element.imei_number,
              type: "daily_tracking",
              options: {
                strokeColor: this.getRandomPathColor(),
                strokeOpacity: 0.8,
                strokeWeight: 3.4,
                icons: [],
              },
              infoText: `IMEI: <b>${this.alarm.imei_number}</b><br>
                        Datum: <b>${date}</b><br>
                        Tillryggalagd sträcka: <b>${Math.ceil(element.distance_travelled / 1000)} km</b>`,
            });
          }
        });

        this.paths_filtered = this.paths;

        this.isLoading = false;
        this.showResult = true;

        // apply existing filter
        this.changeTableFilter(this.table_tab);
        this.changeMapFilter(this.map_filter);
      } catch (error) {
        this.isLoading = false;
        this.handleError(error);
      }
    },

    removePathNoise(alarm) {
      let path = alarm.location_log;
      let paths_filtered = [];

      let distance_travelled_arr = [];
      let distance_travelled = 0;

      let last_speed = 0;

      let speedConversionFactor = 1;
      if (this.alarm && new RegExp(/^SRT/g).test(this.alarm.type)) speedConversionFactor = 1.852;

      for (let i = 0; i < path.length; i++) {
        let element = path[i];

        let alarm_speed = element.speed * speedConversionFactor;

        if (i > 0) last_speed = path[i - 1].speed * speedConversionFactor;

        if (alarm_speed > 10 && last_speed > 5) {
          paths_filtered.push(element);
          distance_travelled_arr.push({ lat: element.lat, lng: element.lng });
        }
      }

      for (let i = 0; i < distance_travelled_arr.length - 1; i++) {
        let pos1 = distance_travelled_arr[i];
        let pos2 = distance_travelled_arr[i + 1];

        distance_travelled += this.getDistanceFromLatLonInKm(pos1, pos2);
      }

      return {
        paths_filtered: paths_filtered,
        distance_travelled: distance_travelled.toFixed(2),
      };
    },

    getDistanceFromLatLonInKm(pos1, pos2) {
      var R = 6371; // Radius of the earth in km
      var dLat = this.deg2rad(pos2.lat - pos1.lat); // deg2rad below
      var dLon = this.deg2rad(pos2.lng - pos2.lng);
      var a = Math.sin(dLat / 2) * Math.sin(dLat / 2) + Math.cos(this.deg2rad(pos1.lat)) * Math.cos(this.deg2rad(pos2.lat)) * Math.sin(dLon / 2) * Math.sin(dLon / 2);
      var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
      var d = R * c; // Distance in km
      return d;
    },

    deg2rad(deg) {
      return deg * (Math.PI / 180);
    },

    getRandomPathColor() {
      var letters = "0123456789ABCDEF";
      var color = "#";
      for (var i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
      }
      return color;
    },

    getAlarmLastSeenTime(updatedAt) {
      return {
        lastSeen: this.moment(updatedAt).fromNow(),
        lastSeenTime: this.moment().diff(this.moment(updatedAt)),
      };
    },

    focusAlarm() {
      var vm = this;

      let current_pos = this.gps;

      if (current_pos && current_pos.gps_pos.lat != null) {
        vm.$refs.map.$mapObject.panTo({ lat: current_pos.gps_pos.lat, lng: current_pos.gps_pos.lng });
        vm.$refs.map.$mapObject.setZoom(16);
      }

      if (this.isMobile) this.mobileMenuActive = false;
    },

    showInfoWindow(event, infoText, show) {
      if (show) {
        this.infoWindowPos = event.latLng;
        this.infoOptions.content = infoText;
        this.infoWinOpen = show;
      } else this.infoWinOpen = show;
    },

    showInfoWindowPath(event, infoText, show, index) {
      if (show) {
        this.infoWindowPos = event.latLng;
        this.infoOptions.content = infoText;
        this.infoWinOpen = show;
        this.paths[index].options.icons = [{ icon: { path: this.google.maps.SymbolPath.FORWARD_CLOSED_ARROW, scale: 2 }, offset: "100%", repeat: "200px" }];
        this.paths[index].options.strokeWeight = 4;
      } else {
        this.infoWinOpen = show;
        this.paths[index].options.icons = [];
        this.paths[index].options.strokeWeight = 3.4;
      }
    },

    changeToggleMap(value) {
      this.toggle_map = value;
      if (this.toggle_map) this.generateBounds();
    },

    changeTableFilter(value) {
      this.table_tab = value;

      if (this.table_tab == 1) this.rows_filtered = this.rows;
      else if (this.table_tab == 2) this.rows_filtered = this.getFilterData("push_button");
      else if (this.table_tab == 3) this.rows_filtered = this.getFilterData("call_b");
      else if (this.table_tab == 4) this.rows_filtered = this.getFilterData("man_down");
      else if (this.table_tab == 5) this.rows_filtered = this.getFilterData("low_battery");
      else if (this.table_tab == 6) this.rows_filtered = this.getFilterData("rft_");
      else if (this.table_tab == 7) this.rows_filtered = this.getFilterData("test_alarm_confirm");
      else if (this.table_tab == 8) this.rows_filtered = this.getFilterData("timer_alarm_stop");
      else if (this.table_tab == 9) this.rows_filtered = this.getFilterData("device_");
      else if (this.table_tab == 10) this.rows_filtered = this.getFilterData("charge_");
      else if (this.table_tab == 11) this.rows_filtered = this.getFilterData("alarm_");
      else if (this.table_tab == 12) this.rows_filtered = this.getFilterData("geofence");
    },

    getFilterData(type) {
      let log_type = type;

      if (log_type == "rft_") {
        let r = _.filter(this.rows, function(o) {
          return new RegExp(/^rft_/).test(o.log_type);
        });
        return r;
      } else if (log_type == "device_") {
        let r = _.filter(this.rows, function(o) {
          return new RegExp(/^device_/).test(o.log_type);
        });
        return r;
      } else if (log_type == "charge_") {
        let r = _.filter(this.rows, function(o) {
          return new RegExp(/^charge_/).test(o.log_type);
        });
        return r;
      } else if (log_type == "alarm_") {
        let r = _.filter(this.rows, function(o) {
          return new RegExp(/^alarm_/).test(o.log_type);
        });
        return r;
      } else if (log_type == "geofence") {
        let r = _.filter(this.rows, function(o) {
          return new RegExp(/^geofence/).test(o.log_type);
        });
        return r;
      } else {
        let r = _.filter(this.rows, { log_type: log_type });
        return r;
      }
    },

    changeMapFilter(value) {
      this.map_filter = value;

      this.markers_filtered = this.markers;
      this.push_button_markers_filtered = this.push_button_markers;
      this.paths_filtered = this.paths;

      if (this.map_filter != 1 && this.map_filter != 2) this.push_button_markers_filtered = [];
      if (this.map_filter != 1 && this.map_filter != 4) this.markers_filtered = [];

      if (this.map_filter != 1) this.paths_filtered = [];
      if (this.map_filter == 3) this.paths_filtered = _.filter(this.paths, { type: "daily_tracking" });
    },

    exportData() {
      const newColumns = this.rows_filtered.map((item) => ({
        IMEI: item.imei_number,
        Timestamp: item.createdAt,
        Report: item.log_report,
        Latitude: item.gps_pos.lat,
        Longitude: item.gps_pos.lng,
      }));

      let filtered = newColumns.filter((el) => Object.keys(el).length);

      this.excelExport(filtered, "log", "larm_logs");
    },

    formatDate(date) {
      return this.moment(date)
        .tz("Europe/Stockholm")
        .format("ddd, YYYY-MM-DD, HH:mm");
    },

    initDateRange() {
      const start = new Date(this.moment().startOf("day"));
      const end = new Date(this.moment().endOf("day"));
      this.date_range = [start, end];
    },

    disableDate(date) {
      const today = new Date();
      today.setHours(0, 0, 0, 0);

      return date > today;
    },

    selectToday(emit) {
      const start = new Date(this.moment().startOf("day"));
      const end = new Date(this.moment().endOf("day"));
      const date = [start, end];
      emit(date);
    },

    selectCurrentWeek(emit) {
      const start = new Date(this.moment().startOf("isoWeek"));
      const end = new Date(this.moment().endOf("day"));
      const date = [start, end];
      emit(date);
    },

    selectLastWeek(emit) {
      const start = new Date(
        this.moment()
          .subtract(1, "weeks")
          .startOf("isoWeek")
      );
      const end = new Date(
        this.moment()
          .subtract(1, "weeks")
          .endOf("isoWeek")
      );
      const date = [start, end];
      emit(date);
    },

    selectCurrentMonth(emit) {
      const start = new Date(this.moment().startOf("month"));
      const end = new Date(this.moment().endOf("day"));
      const date = [start, end];
      emit(date);
    },

    selectLastMonth(emit) {
      const start = new Date(
        this.moment()
          .subtract(1, "month")
          .startOf("month")
      );
      const end = new Date(this.moment(start).endOf("month"));
      const date = [start, end];
      emit(date);
    },

    checkMobile() {
      var vm = this;

      this.isMobile = window.innerWidth < 1024;

      if (this.isMobile) {
        vm.mobileMenuActive = false;
        vm.mapFullScreenControl = false;
      } else {
        this.mobileMenuActive = true;
        this.mapFullScreenControl = true;
      }
    },

    closeMenu() {
      this.mobileMenuActive = false;
    },

    generateMobileMenu() {
      var vm = this;

      let controlDiv = this.generateGoogleMapActionDiv();

      controlDiv.addEventListener("click", () => {
        vm.mobileMenuActive = true;
      });

      vm.$refs.map.$mapPromise.then((map) => {
        map.controls[vm.google.maps.ControlPosition.TOP_RIGHT].push(controlDiv);
      });
    },

    generateBounds() {
      var vm = this;
      if (this.markers_filtered.length > 0) {
        setTimeout(() => {
          vm.$refs.map.$mapPromise.then((map) => {
            const bounds = new vm.google.maps.LatLngBounds();
            for (let m of this.markers_filtered) {
              bounds.extend(m.position);
            }

            var offset = 0.002;
            var center = bounds.getCenter();
            bounds.extend(new vm.google.maps.LatLng(center.lat() + offset, center.lng() + offset));
            bounds.extend(new vm.google.maps.LatLng(center.lat() - offset, center.lng() - offset));

            map.fitBounds(bounds);
          });
        }, 500);
      }
    },
  },

  computed: {
    google: gmapApi,
    user() {
      return this.$store.state.user;
    },
  },

  created() {
    this.initDateRange();

    window.addEventListener("resize", this.checkMobile);
  },

  mounted() {
    this.generateMobileMenu();
    this.checkMobile();
    this.setPageTitle(`SecurTool - Tekniker`, "tech_securtool");
  },

  beforeDestroy() {
    window.removeEventListener("resize", this.checkMobile);
  },
};
</script>
